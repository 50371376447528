import React from 'react';
import Map from './Map.js';
import AudioPreviewPlayer from './AudioPreviewPlayer.js';
import { colorizers } from './colorizers.js'
import Cookies from 'js-cookie';
import { loginUrl } from './auth.js'
import { calcMap, runCounter, incRunCounter } from './calcMap.js'
import { useLiveQuery } from "dexie-react-hooks";
import { useLoaderData } from 'react-router-dom';
import { makeBundle } from './utils.js'

import imgTempo from './img/tempo.svg'
import imgDanceability from './img/danceability.svg'
import imgValence from './img/valence.svg'
import imgEnergy from './img/energy.svg'
import imgInstrumentalness from './img/instrumentalness.svg'
import imgAcousticness from './img/acousticness.svg'
import imgLiveness from './img/liveness.svg'
import imgSpeechiness from './img/speechiness.svg'
import imgNext from './img/noun-play-next-2005363-FFFFFF.svg'
import imgPause from './img/noun-pause-circle-2656949-FFFFFF.svg'
import imgPlay from './img/noun-play-circle-2656951-FFFFFF.svg'


const MapWrapper = (props) => {
  const loaderData = useLoaderData();

  const [colorizer, setColorizer] = React.useState(() => 'energy');
  const [playerActive, setPlayerActive] = React.useState(false);

  const [tempColorizer, setTempColorizer] = React.useState(null);

  const [recentTracks, setRecentTracks] = React.useState([]);
  const [tracks, setTracks] = React.useState([]);

  React.useEffect(() => {
    if (props.name) {
      updateTracks();
    }
  }, [props.name, props.selectedPlaylist]);

  React.useEffect(() => {
    if (tracks) {
      // console.log('tracks', tracks);
      const maps = db2.getCollection('maps');
      console.log('MapWrapper map name', props.name);
      let map = maps.find({mapName: props.name});
      if (map.length == 0) {
        console.log('create new map', tracks.length);
        // console.log('tids', tracks.map(t => t.id).sort());
        tracks.map(t => {
          try {
            let mapTrackId = props.name+'-'+t.id;
            maps.removeWhere({'mapTrackId': mapTrackId});
            maps.insert({...t, ...{mapName: props.name, mapTrackId: mapTrackId}});
          } catch (e) {
            console.error(e, t, props.name);
          }
        }); 
      }
      updateMap();
    }
  }, [tracks]);

  function bundleTracks() {
    // example: "bundle-37i9dQZF1DXe1cC3XInKct"
    const [ _bundle, playlistId ] = props.name.split('-')
    // console.log(_bundle, playlistId);
    const playlists = db2.getCollection('playlists').data;
    return makeBundle(playlists, playlistId);
  }

  function updateTracks() {
    console.log("updateTracks for map named", props.name);
    if (loaderData.tabName !== 'playlists') {
      const isYearFiltered = loaderData.tabName !== 'playlists' && !!parseInt(props.name);
      const fromSavedTracks = isYearFiltered
        ? db2.getCollection('tracks').chain().find({trackSavedYear: parseInt(props.name)}).data({removeMeta: true})
        : props.name == 'all'
          ? db2.getCollection('tracks').chain().compoundsort([['added_at', true]]).limit(3000).data({removeMeta: true})
          : [];
      const tids = fromSavedTracks.map(t => t.id);
      window.albums = db2.getCollection('albums');
      const fromAlbums = isYearFiltered
        ? db2.getCollection('albums').chain()
            .find({albumSavedYear: parseInt(props.name)})
            .mapReduce(a => a.tracksWithFeatures, trackss => trackss.flat(1))
            .filter(t => {
              return t && !tids.includes(t.id);
            })
        : props.name == 'all'
          ? db2.getCollection('albums').chain()
              .compoundsort([['added_at', true]])
              .limit(1000)
              .mapReduce(a => a.tracksWithFeatures, trackss => trackss.flat(1))
              .filter(t => {
                return t && !tids.includes(t.id);
              })
          : [];
      let combinedTracks = fromSavedTracks.concat(fromAlbums);
      if (combinedTracks.length > 5000) {
        combinedTracks = combinedTracks
          .sort((a, b) => (a.added_at < b.added_at) ? 1 : ((a.added_at > b.added_at) ? -1 : 0))
          .slice(0, 5000);
      }
      setTracks(combinedTracks);
    } else if (loaderData.tabName == 'playlists') {
      const fromPlaylists = props.selectedPlaylist
        ? props.selectedPlaylist.tracksWithFeatures
        : bundleTracks();
      // console.log('fromPlaylists', props.selectedPlaylist, fromPlaylists);
      setTracks(fromPlaylists);
    }

    // console.log('fromAlbums', fromAlbums);
  }

  function refreshMap() {
    const maps = db2.getCollection('maps');
    maps.findAndRemove({mapName: props.name});
    updateTracks();
  }

  const aryMappableTracks = React.useRef([]);

  async function updateMap(force) {
    incRunCounter();
    const map = db2.getCollection('maps').find({mapName: props.name});
    aryMappableTracks.current = map;
    console.log('UPDATE MAP', force ? 'force' : 'no-force', runCounter, aryMappableTracks.current.length);

    // can't filter to unmapped here because even when adding 1 track,
    // all positions need to be recalculated

    // aryMappableTracks.current = tracks;
    let optimizedDotSize = props.dotSize;
    if (aryMappableTracks.current.length > 1) {
      optimizedDotSize = 2.8/Math.log(aryMappableTracks.current.length)-0.2;
      props.setDotSize(optimizedDotSize);
    } else {
      optimizedDotSize = 3.5;
      props.setDotSize(optimizedDotSize);
    }

    if (force || aryMappableTracks.current.some(t => t.x == null || t.y == null)) {
      // console.log('recalculating positions', force, aryMappableTracks.current.filter(t => t.x == null || t.y == null));
      aryMappableTracks.current.forEach(t => {
        t.x = null;
        t.y = null;
      });

      let result = await calcMap(
        aryMappableTracks.current,
        props.setEpoch,
        0.5, // minDist,
        parseFloat(props.spread),
        optimizedDotSize
      );
      console.log("calc result", result);
      if (result) {
        props.setForceUpdate(false);
        console.log('finished', aryMappableTracks.current.filter(t => t.x == null || t.y == null));
        const maps = db2.getCollection('maps');
        aryMappableTracks.current.forEach(mapTrack => {
          maps.findAndUpdate(
            {mapTrackId: mapTrack.mapTrackId},
            mp => { mp.x = mapTrack.x; mp.y = mapTrack.y; });
        });
        console.log("embedding refreshed.");
      }
    }
  }

  // React.useEffect(() => {
  //   const interval = setInterval(async () => {
  //     if (!props.token || props.quick) return;
  //     try {
  //       // console.log('token', props.token);
  //       let recentTracks = await spotifyApi.getMyRecentlyPlayedTracks({limit: 50});
  //       // console.log('recentTracks', recentTracks.items.map(i => i.track.name)); // .map(t => t.item.name));
  //       setRecentTracks(recentTracks);
  //     } catch (e) {
  //       if (e.status == 401) {
  //         props.clearToken();
  //       } else {
  //         console.error(e);
  //       }
  //     }
  //   }, 2000);

  //   // https://devtrium.com/posts/set-interval-react
  //   return () => clearInterval(interval);
  // }, []);

  React.useEffect(() => {
    addEventListener('keydown', e => {
      if (e.key === "Escape") {
        let elem = document.getElementById('search');
        if (elem) elem.value = '';
        props.setSearch('');
      }
    });
  }, []);

  function progressWidth(playingTrack) {
    return '' + Math.ceil(100 * props.currentlyPlayingTrack.progress_ms / props.currentlyPlayingTrack.item.duration_ms) + '%';
  }

  function skipToNext() {
    spotifyApi.skipToNext();
  }

  return (
    <div id="mapWrapper">
      <div id="mapAndPlayer">
        <Map
          search={props.search}
          tracks={aryMappableTracks}
          quick={props.quick}
          pendingMapUpdate={props.pendingMapUpdate}
          spread={props.spread}
          colorizerFn={colorizers[tempColorizer || colorizer]}
          dotSize={props.dotSize} setDotSize={props.setDotSize}
          playerActive={playerActive}
          setPlayerActive={setPlayerActive}
          playTrack={props.playTrack} setPlayTrack={props.setPlayTrack}
          play={props.play}
          token={props.token} clearToken={props.clearToken}
          currentlyPlayingTrack={props.currentlyPlayingTrack}
          recentTracks={recentTracks}
          hoveredAlbum={props.hoveredAlbum}
          hoveredPlaylist={props.hoveredPlaylist}
          queue={props.queue}
        />
        <AudioPreviewPlayer
          pendingMapUpdate={props.pendingMapUpdate}
          playerActive={playerActive}
          setPlayerActive={setPlayerActive}
          playTrack={props.playTrack}
          quick={props.quick}
        />
        <div className="newmap">
          <a
            onClick={() => refreshMap()}>
            refresh map
          </a>
          {/*<a
            onClick={props.deleteMap}>
            delete map
          </a>*/}
          <a
            onClick={props.resetApp}>
            reset app
          </a>
          <a href="https://paypal.me/thefronx">
            donate
          </a>
          <a href="/privacy-policy.html">
            privacy
          </a>
          <a href="/impressum.html">
            impressum
          </a>
          {props.token ? (
            <a key="logout" onClick={e => props.logout()}>logout</a>
          ) : (
            <a key="login" href={loginUrl}>login</a>
          )}
        </div>
      </div>
      <div className="vertBar">
        <h2>MusicMapper</h2>
        <div className="search">
          <input type="text" name="search" id="search"
            onChange={ e => e.target.value.length > 2 ? props.setSearch(e.target.value) : props.setSearch('') } 
          />
          <label htmlFor="search">🔎</label>
        </div>
        <ul className="view">
          <li><a className={colorizer == "tempo" ? "current" : ""} onClick={e => setColorizer('tempo')} onMouseOver={e => setTempColorizer('tempo')} onMouseLeave={e => setTempColorizer(null)}>
            <img src={imgTempo} /><span>tempo</span></a></li>
          <li><a className={colorizer == "danceability" ? "current" : ""} onClick={e => setColorizer('danceability')} onMouseOver={e => setTempColorizer('danceability')} onMouseLeave={e => setTempColorizer(null)}>
            <img src={imgDanceability} /><span>danceability</span></a></li>
          <li><a className={colorizer == "valence" ? "current" : ""} onClick={e => setColorizer('valence')} onMouseOver={e => setTempColorizer('valence')} onMouseLeave={e => setTempColorizer(null)}>
            <img src={imgValence} /><span>valence</span></a></li>
          <li><a className={colorizer == "energy" ? "current" : ""} onClick={e => setColorizer('energy')} onMouseOver={e => setTempColorizer('energy')} onMouseLeave={e => setTempColorizer(null)}>
            <img src={imgEnergy} /><span>energy</span></a></li>
          <li><a className={colorizer == "instrumentalness" ? "current" : ""} onClick={e => setColorizer('instrumentalness')} onMouseOver={e => setTempColorizer('instrumentalness')} onMouseLeave={e => setTempColorizer(null)}>
            <img src={imgInstrumentalness} /><span>instrumentalness</span></a></li>
          <li><a className={colorizer == "acousticness" ? "current" : ""} onClick={e => setColorizer('acousticness')} onMouseOver={e => setTempColorizer('acousticness')} onMouseLeave={e => setTempColorizer(null)}>
            <img src={imgAcousticness} /><span>acousticness</span></a></li>
          <li><a className={colorizer == "liveness" ? "current" : ""} onClick={e => setColorizer('liveness')} onMouseOver={e => setTempColorizer('liveness')} onMouseLeave={e => setTempColorizer(null)}>
            <img src={imgLiveness} /><span>liveness</span></a></li>
          <li><a className={colorizer == "speechiness" ? "current" : ""} onClick={e => setColorizer('speechiness')} onMouseOver={e => setTempColorizer('speechiness')} onMouseLeave={e => setTempColorizer(null)}>
            <img src={imgSpeechiness} /><span>speechiness</span></a></li>
        </ul>
        {props.currentlyPlayingTrack ? (
          <div id="currentlyPlaying">
            {props.currentlyPlayingTrack.is_playing ? (
              <img src={imgPause} className="button" onClick={e=> spotifyApi.pause()} />
            ) : (
              <img src={imgPlay} className="button" onClick={e=> spotifyApi.play()} />
            )}
            <h3>{props.currentlyPlayingTrack.item.name}</h3>
            <p>{props.currentlyPlayingTrack.item.artists.map(a => a.name).join(', ')}
            </p>
            <a href={props.currentlyPlayingTrack.item.album.external_urls.spotify} target="_blank">
              <img src={props.currentlyPlayingTrack.item.album.images[0].url}
                />
            </a>
            <div className="progressBg">
              <div className="progress" style={{width: progressWidth(props.currentlyPlayingTrack)}}></div>
            </div>
          </div>
          ) : ''}
        {props.queue.length > 0 ? (
          <div className="queue">
            <h4>Next up</h4>
            <div className="queuedTracks">
            {props.queue.slice(0, 9).reverse().map((track, i) => (
              <img key={i} src={track?.album?.images[2].url}/>
            ))}
              <img src={imgNext} className="button"
                onClick={e => skipToNext()}
                />
            {props.queue.slice(9, 19).reverse().map((track, i) => (
              <img key={i} src={track?.album?.images[2].url}/>
            ))}
            </div>
          </div>
        ) : ''}
      </div>
    </div>
  );
};

export default MapWrapper;
