import React from 'react';
import { Link } from 'react-router-dom';
import { loginUrl } from './auth.js'
import { makeBundles } from './utils.js'

import imgReload from './img/noun-reload-1015725-FFFFFF.svg'
import imgX from './img/noun-x-2172392-FFFFFF.svg'


function bundleName(ps) {
  const top4 = [...ps].sort((a, b) => a.trackCount > b.trackCount ? -1 : 1).slice(0, 4);
  return top4.map(p => p.name).join(', ') + "…"
}

const PlaylistLoader = (props) => {
  const [playlists, setPlaylists] = React.useState([]);
  const [bundles,   setBundles]   = React.useState([]);

  React.useEffect(() => {
    const ps = db2.getCollection('playlists').data;
    setPlaylists(ps);
    setBundles(makeBundles(ps));
  }, [props.playlistsOffset]);

  function toggleSelect(playlist) {
    setSelect(playlist, 1 - playlist.selected);
  }

  function setSelect(playlist, selected) {
    props.setSelectedPlaylist(playlist);
  }

  function selectAll() {
  }

  async function deselectAll() {
  }

  const playingIds = props.playTrack && props.playTrack.playlistIds
    ? props.playTrack.playlistIds : [];

  function className(p) {
    let result = [];
    if (props.search.length > 2 && !matchSearch(p)) result.push("nomatch");
    if (p == props.selectedPlaylist) result.push("selected");
    if (playingIds.includes(p.id)) result.push("playing");
    if (p.offset == null && p.tracksWithFeatures.length < p.trackCount)
      result.push("loading");
    return result.join(' ');
  }

  function loadedTrackCount(p) {
    if (!p.tracksWithFeatures) {
      return '';
    }
    return p.tracksWithFeatures.length >= p.trackCount ? p.tracksWithFeatures.length :
      "" + p.tracksWithFeatures.length + "/" + p.trackCount;
  }

  const search = props.search.toLowerCase();

  function matchSearch(p) {
    if (p.name.toLowerCase().includes(search)) return true;
    return false;
  }

  return (
    <>
      <div className="bundles rows">
        <div className="intro">
          <p>
            FYI: Most recent playlists are on top. <br/>
            Bundles group the playlists below.
          </p>
        </div>
        <div className="icons">
          <div className="right">
            <a onClick={() => props.loadPlaylists()} title="reload playlists">
              <img className="icon reload" src={imgReload} />
            </a>
            {/*<img className="icon deselectAll" src={imgX} onClick={() => deselectAll()} />*/}
          </div>
        </div>
        {bundles?.length > 0 ? (
          <div className="scroller">
            <ul>
            {bundles?.map((ps, i) => (
              <li key={"bundle-"+i}>
                {i > 0 ? (
                  <br />
                ) : ''}
                {ps.length > 0 ? (
                  <Link className="bundle" to={"/bundles/" + ps[0].id}>
                    <h3 className="name">
                      Bundle {i+1} <span>{bundleName(ps)}</span>
                    </h3>
                  </Link>
                ) : ''}
                <ul>
                {ps.map(p => (
                  <li key={"playlist-"+p.id}
                    className={className(p)}
                    onMouseOver={e => props.setHoveredPlaylist(p)}
                    onMouseLeave={e => props.setHoveredPlaylist(null)}
                    >
                    <Link className="playlist" to={"/playlists/" + p.id}>
                      {p.images && p.images[0] ? (
                        <img src={p.images[0].url} height={32} width={32} />
                      ) : (
                        <span className="loadingIndicator"></span>
                      )}
                      <span className="title">
                        {p.name}
                      </span>
                      <span className="counts">
                        {loadedTrackCount(p)}
                      </span>
                    </Link>
                    {/* <a href={p.external_urls.spotify} className="spotify">⎋</a> */}
                  </li>
                ))}
                </ul>
              </li>
            ))}
            </ul>
          </div>
          ) : (
            <div className="info">
              No playlists loaded yet.<br />
              {!props.token ? (
                <><a href={loginUrl}>Login to Spotify</a> to start.</>
              ) : (
                <a onClick={props.loadPlaylists}>Import from Spotify</a>
              )}
            </div>
          )}
      </div>
    </>
  );
};

export default PlaylistLoader;
