'use strict';

import React from 'react';

import axios from 'axios';
import { SpotifyApiContext, SpotifyApiAxiosContext } from 'react-spotify-api';
import Cookies from 'js-cookie';
import { getTokenFromUrl } from './auth.js'

import './App.css';
import Loader from './Loader.js';
import MapWrapper from './MapWrapper.js';
import { loginUrl } from './auth.js'
import { useLoaderData } from 'react-router-dom';


function App() {
  const loaderData = useLoaderData();
  // console.log("loaderData", loaderData);

  const [token, setToken] = React.useState(Cookies.get("spotifyAuthToken"));
  const [epoch, setEpoch] = React.useState(-1);
  const [spread, setSpread] = React.useState('2');
  const [dotSize, setDotSize] = React.useState('0.2');
  const [search, setSearch] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const [playTrack, setPlayTrack] = React.useState(null);
  const [selectedPlaylistsWithFeaturesCount, setSelectedPlaylistsWithFeaturesCount] = React.useState(0);
  const [pendingMapUpdate, setPendingMapUpdate] = React.useState(false);
  const [hoveredAlbum, setHoveredAlbum] = React.useState(null);
  const [hoveredPlaylist, setHoveredPlaylist] = React.useState(null);
  const [mapName, setMapName] = React.useState(loaderData.mapName);
  const [selectedPlaylist, setSelectedPlaylist] = React.useState(null);
  const [queue, setQueue] = React.useState([]);
  // To clarify: this is not the hovered track — it's the track that's playing
  // on your Spotify account.
  const [currentlyPlayingTrack, setCurrentlyPlayingTrack] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState(loaderData.tabName);


  React.useEffect(() => {
    if (loaderData.tabName !== activeTab) {
      setActiveTab(loaderData.tabName);
    }
    if (loaderData.mapName !== mapName) {
      setMapName(loaderData.mapName);
      if (loaderData.tabName == 'playlists') {
        setSelectedPlaylist(db2.getCollection('playlists').findOne({ id: loaderData.mapName }));
      } else {
        setSelectedPlaylist(null);
      }
    }
  }, [loaderData]);

  React.useEffect(() => {
    console.log("token", token);
  }, [token]);

  async function getQueuedTracks() {
    if (!token) return [];
    try {
      let res = await spotifyApi.getQueue();
      // console.log('queue', res);
      // let tracks = res.queue.filter(item => item.type == 'track');
      return res.queue;
    } catch (e) {
      if (e.status == 401) {
        clearToken();
      } else {
        console.error(e);
      }
    }
    return [];
  }

  // React.useEffect(() => {
  //   const interval = setInterval(async () => {
  //     setQueue(await getQueuedTracks());
  //   }, 2000);

  //   // https://devtrium.com/posts/set-interval-react
  //   return () => clearInterval(interval);
  // }, []);

  // React.useEffect(() => {
  //   const interval = setInterval(async () => {
  //     if (!token) return;
  //     try {
  //       let cpt = await spotifyApi.getMyCurrentPlayingTrack();
  //       // if (cpt) console.log('playing', cpt);
  //       setCurrentlyPlayingTrack(cpt && cpt.item ? cpt : null);
  //     } catch (e) {
  //       if (e.status == 401) {
  //         props.clearToken();
  //       } else {
  //         console.error(e);
  //       }
  //     }
  //   }, 2000);

  //   // https://devtrium.com/posts/set-interval-react
  //   return () => clearInterval(interval);
  // }, []);

  async function play(t, options) {
    setPlayTrack(null);
    console.log(t);
    let ds = (await spotifyApi.getMyDevices()).devices;
    console.log('devices', ds);
    if (ds.length == 0) {
      console.log("TODO: tell user to connect a spotify client app to play");
    } else {
      let device = ds.find(d => d.is_active || d.type == 'Tablet') || ds[0];
      try {
        if (options) {
          spotifyApi.play({...{
            device_id: device.id,
          }, ...options});
        } else {
          let queuedTracks = await getQueuedTracks();
          if ((currentlyPlayingTrack && !currentlyPlayingTrack.is_playing)
              || queuedTracks.length == 0) {
            spotifyApi.play({...{
              uris: [t.uri],
              device_id: device.id,
            }, ...options});
          } else {
            await spotifyApi.queue(t.uri, {
              device_id: device.id,
            });
            setQueue(await getQueuedTracks());
          }
        }
      } catch (e) {
        if (e.status == 401) {
          clearToken();
        } else if (e.status == 502) {
          window.open(t.href, '_blank').focus();
        } else {
          console.error(e);
        }
      }
    }
  }

  async function resetApp() {
    db2.deleteDatabase();
    window.location.href = "/";
  }

  function clearToken() {
    Cookies.remove("spotifyAuthToken");
    setToken(null);
  }

  function logout() {
    clearToken();
  }

  const accessToken = getTokenFromUrl();
  if (accessToken) {
    setToken(accessToken);
    Cookies.set("spotifyAuthToken", accessToken);
    window.location.href = "/";
  }

  return (
    <div className="App">
      <header className="App-header">
        <SpotifyApiAxiosContext.Provider value={axios}>
          <SpotifyApiContext.Provider value={token}>
            <Loader
              token={token}
              loading={loading}
              setLoading={setLoading}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              playTrack={playTrack}
              play={play}
              setPlayTrack={setPlayTrack}
              clearToken={clearToken}
              search={search}
              setHoveredAlbum={setHoveredAlbum}
              setHoveredPlaylist={setHoveredPlaylist}
              mapName={mapName}
              setMapName={setMapName}
              selectedPlaylist={selectedPlaylist}
              setSelectedPlaylist={setSelectedPlaylist}
            />
            <MapWrapper
              name={mapName}
              selectedPlaylist={selectedPlaylist}
              quick={epoch != -1}
              setEpoch={setEpoch}
              setForceUpdate={setForceUpdate}
              spread={parseFloat(spread)}
              dotSize={parseFloat(dotSize)} setDotSize={setDotSize}
              playTrack={playTrack} setPlayTrack={setPlayTrack}
              play={play}
              pendingMapUpdate={pendingMapUpdate}
              search={search} setSearch={setSearch}
              resetApp={resetApp}
              logout={logout}
              token={token} clearToken={clearToken}
              hoveredAlbum={hoveredAlbum}
              hoveredPlaylist={hoveredPlaylist}
              queue={queue}
              currentlyPlayingTrack={currentlyPlayingTrack}
            />
            {!token ? (
              <div className="overlay">
                <div className="centered">
                  <a className="login" key="login" href={loginUrl}>Log in to your Spotify account</a>
                  <p>Logins last 1 hour and need to be refreshed after that.</p>
                </div>
              </div>
            ) : ''}
          </SpotifyApiContext.Provider>
        </SpotifyApiAxiosContext.Provider>
        </header>
    </div>
  );
}

export default App;
