import React from 'react';
import { db, mapped, mappable, aryMappableTracks } from './db.js';
import { useLiveQuery } from "dexie-react-hooks";
import * as d3 from "d3";
import { calcArtistLabels } from './calc.js'
import { makeNonOverlapping } from './layout.js'

var SpotifyWebApi = require('spotify-web-api-js');


function featRound(feat) {
  return Math.round(feat * 100);
}

const Map = (props) => {
  const spotifyApi = React.useMemo(
    () => {
      const swa = new SpotifyWebApi();
      swa.setAccessToken(props.token);
      return swa;
    },
    [props.token]
  );

  React.useEffect(() => {
    console.log("Map props.tracks changed", props.tracks);
  }, [props.tracks]);

  // const dbTracks = props.map ? props.map.tracks : [];
  const tracks = props.tracks.current;
  // console.log('Map: tracks', tracks);

  const f = 9;
  let minX = -props.spread * f;
  let maxX =  props.spread * f;
  let minY = -props.spread * f;
  let maxY =  props.spread * f;

  const [viewBox, setViewBox] = React.useState([
    minX,
    minY,
    (maxX - minX),
    (maxY - minY),
  ]);

  React.useEffect(() => {
    let mappedTracks = mapped(tracks);
    // console.log("Effect Map: ", mappedTracks.length, "/", tracks.length);
    if (tracks.length > 1) {
      let optimizedDotSize = 2.8/Math.log(tracks.length)-0.2;
      props.setDotSize(optimizedDotSize);
    } else {
      props.setDotSize(3.5);
    }
    if (mappedTracks.length > 0 && (mappedTracks.length == tracks.length)) {
      if (!props.quick) {
        mappedTracks.forEach(t => {
          t.dotSize = dotSize(t);
        });
        makeNonOverlapping(mappedTracks, artistLabels);
      }
    } else {
      console.log("map non-overlapping not triggered", mappedTracks.length, tracks.length);
    }
  }, [props.quick, props.dotSize]);

  const [recentTrackIds, setRecentTrackIds] = React.useState([]);
  const [queueTrackIds, setQueueTrackIds] = React.useState([]);

  React.useEffect(() => {
    let ids = props.recentTracks && props.recentTracks.items
      ? props.recentTracks.items.map(rt => rt.track.id)
      : [];
    if (props.currentlyPlayingTrack) {
      ids.unshift(props.currentlyPlayingTrack.item.id);
    }
    if (JSON.stringify(ids) !== JSON.stringify(recentTrackIds)) {
      setRecentTrackIds(ids);
    }
  }, [props.recentTracks, props.currentlyPlayingTrack]);

  React.useEffect(() => {
    let ids = props.queue ? props.queue.filter(t => !!t).map(t => t.id) : [];
    if (JSON.stringify(ids) !== JSON.stringify(queueTrackIds)) {
      setQueueTrackIds(ids);
      // console.log("setQueueTrackIds", ids);
    }
  }, [props.queue]);

  React.useEffect(() => {
    if (!props.quick) {
      let mappedTracks = mapped(tracks);
      mappedTracks.forEach(t => {
        t.dotSize = dotSize(t);
      });
      makeNonOverlapping(mappedTracks, artistLabels);
    }
  }, [recentTrackIds, queueTrackIds]);

  const fMin = 1.7;
  const fRange = 1;
  const fPlaying = 3;
  const factors = [fPlaying].concat([...Array(50).keys()].map(i =>
    fMin + fRange * (1.0 - (i/50.0))));

  function recencyFactor(t) {
    let index = recentTrackIds.indexOf(t.id);
    if (index == 0) return factors[index];
    if (queueTrackIds.includes(t.id)) return 1.7;
    if (index > 0) return factors[index];
    return 1.0;
  }

  function matchSearch(track) {
    if (props.search.length < 3) return false;
    let s = props.search.toLowerCase();
    if (track.artist.toLowerCase().includes(s)) return true;
    if (track.name.toLowerCase().includes(s)) return true;
    if (track.album.toLowerCase().includes(s)) return true;
    return false;
  }

  function isOfHoveredPlaylistOrAlbum(t) {
    if (props.hoveredPlaylist?.trackIds) {
      // console.log("props.hoveredPlaylist", props.hoveredPlaylist);
      return props.hoveredPlaylist.trackIds.includes(t.id);
    }
    if (props.hoveredAlbum?.trackIds) {
      return props.hoveredAlbum.trackIds.includes(t.id);
    }
    return false;
  }

  function dotSize(t) {
    if (!isMapped(t)) return 0;
    return props.dotSize * recencyFactor(t) * (t.match ? 1.3 : 1);
  }

  function faded(fs, fl, color) {
    let c = d3.hsl(color);
    return d3.hsl(c.h, c.s * fs, c.l * fl);
  }

  function highlight(color) {
    return d3.interpolateRgb(color, "#aaa")(0.8);
  }

  function highlightMore(color) {
    return d3.interpolateRgb(color, "#aaa")(0.9);
  }

  function hover(t) {
    props.setPlayTrack(t);
  }

  function leave(t) {
    props.setPlayTrack(null);
  }

  function dotId(n, t) {
    return 'dot-' + n + '-' + t.id;
  }

  function mouseMove(e) {
    let hoverTrack = document.getElementById('hoverTrack');
    if (hoverTrack) {
      let wrapper = document.getElementById('svgWrapper');
      hoverTrack.style.left = 20 + e.nativeEvent.offsetX + 'px';
      hoverTrack.style.top = -45 + e.nativeEvent.offsetY + 'px';
    }
  }

  if (tracks) {
    tracks.forEach(t => {
      t.color = d3.interpolatePlasma(props.colorizerFn(t));
      t.match = matchSearch(t) || isOfHoveredPlaylistOrAlbum(t);
    })
  }

  function isMapped(t) {
    return t.is_mapped == 1;
  }

  function dimmed() {
    return searching || props.hoveredPlaylist;
  }

  function dotColor(t) {
    return t.match
      ? 'white'
      : dimmed()
        ? faded(0.3, 0.5, t.color)
        : ht && (t.artist == ht.artist) && (t.artist !== "Various Artists")
          ? highlight(t.color)
          : queueTrackIds.includes(t.id)
            ? highlightMore(t.color)
            : t.color;
  }

  function isCurrentlyPlaying(t) {
    return !!props.currentlyPlayingTrack &&
      t.id == props.currentlyPlayingTrack.item.id;
  }

  function className(t) {
    let result = [];
    if (isCurrentlyPlaying(t)) result.push('playing');
    return result.join(' ');
  }

  // console.log("EVAL Map.js");

  const ht = props.quick ? null : props.playTrack;
  const hp = props.quick ? null : props.hoveredPlaylist;

  if (!tracks) return null;

  const searching = props.search.length >= 3;
  var artistLabels = props.quick || dimmed() ? [] : calcArtistLabels(tracks);
  if (artistLabels.length < 3) artistLabels = [];

  return (
    <div id="svgWrapper" className={props.pendingMapUpdate ? "pending" : ""}>
      <div className="rel">
        <svg
          id="svgMap"
          className="map" viewBox={viewBox.join(' ')}
          fontSize={viewBox[2]/60}
          onMouseMove={mouseMove}
          >
          <defs>
            <filter x="0" y="0" width="1" height="1" id="solid">
              <feFlood floodColor="rgba(50,50,50,0.8)" result="bg" />
              <feMerge>
                <feMergeNode in="bg"/>
                <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
          </defs>
          <g id="dot0">
            {tracks.map(t => {
              return (
                <circle
                  id={dotId(0, t)} // id is important for D3!
                  key={dotId(0, t)} r={props.dotSize} cx={t.x} cy={t.y}
                  className={className(t)}
                  fill={dotColor(t)}
                  stroke={"#111"}
                  strokeWidth={0.02}
                  />
            )})}
          </g>
          {props.quick ? "" : (
            <>
              <g>
                {artistLabels.map(al => (
                  <text id={"artist-" + al.artist} key={"artist-" + al.artist} className="artistLabel" textAnchor="middle" x={al.x} y={al.y}>{al.artist}</text>
                ))}
              </g>
              <g id="dot1">
              {tracks.map(t => {
                // href={t.href} target="_blank"
                if (isMapped(t)) return (
                  <a key={'a-dot-' + t.id}
                    onClick={e => props.play(t)}
                    onMouseEnter={e => hover(t)}
                    onMouseLeave={e => leave(t)}>
                    <circle id={dotId(1, t)} key={dotId(1, t)} r={props.dotSize} cx={t.x} cy={t.y}
                      className={className(t)}
                      fill='transparent'
                    />
                  </a>
              )})}
              </g>
            </>
          )}
        </svg>
        {props.playerActive && ht ? (
          <div id="hoverTrack">
            <div className="head">
              <p className="track">{ht.name + "   "}</p>
              <p className="artist">{ht.artist + "   "}</p>
              <p className="releaseYear">({ht.release_year}) {ht.album + "   "}</p>
            </div>
            <div className="features">
              <p><span>{Math.round(ht.tempo)}</span> bpm</p>
              <p><span>{featRound(ht.danceability)}</span> danceable</p>
              <p><span>{featRound(ht.instrumentalness)}</span> instrumental</p>
              <p><span>{featRound(ht.valence)}</span> valence</p>
              <p><span>{featRound(ht.energy)}</span> energy</p>
              <p><span>{featRound(ht.acousticness)}</span> acousticness</p>
              <p><span>{featRound(ht.liveness)}</span> liveness</p>
              <p><span>{featRound(ht.speechiness)}</span> speechiness</p>
            </div>
           </div>
        ) : ""}
      </div>
    </div>
  )
}

export default Map;
