export function chunked(arr, chunkSize) {
  if (!Number.isInteger(chunkSize)) {
    throw 'Chunk size must be an integer.';
  }

  if (chunkSize < 1) {
    throw 'Chunk size must be greater than 0.';
  }

  const groups = [];
  let i = 0;
  while (i < arr.length) {
    groups.push(arr.slice(i, i += chunkSize));
  }
  return groups;
};

export function makeBundles(playlists) {
  // make copy that is safe to mutate
  const ps = [...playlists];
  const limit = 2000;
  let bundles = [];
  let bundleSize = 0;
  while (ps.length > 0) {
    const p = ps.shift();
    if ((bundleSize > 0) && (bundleSize + p.trackCount > limit)) {
      bundles.push([]);
      bundleSize = 0;
    }
    if (bundles.length == 0) bundles.push([]);
    bundles[bundles.length - 1].push(p);
    bundleSize += p.trackCount;
  }
  return bundles;
}

export function makeBundle(playlists, playlistId) {
  if (playlistId == undefined) {
    return [];
  }
  // make copy that is safe to mutate
  const ps = [...playlists];
  console.log('ps', ps.length);
  const limit = 2000;

  while ((ps.length > 0) && (ps[0].id !== playlistId)) {
    console.log(ps[0].id, playlistId);
    ps.shift();
  }
  if (ps.length == 0) return [];
  let bundle = [];
  let bundleSize = 0;
  if (ps[0].id == playlistId) {
    let tracks = ps.shift().tracksWithFeatures;
    bundle.push(...tracks);
    bundleSize += tracks.length;
    while ((ps.length > 0) && (bundleSize + ps[0].trackCount < limit)) {
      tracks = ps.shift().tracksWithFeatures;
      bundle.push(...tracks);
      bundleSize += tracks.length;
    }
  }
  return bundle;
}
